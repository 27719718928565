
import ExaminationHeader from '@/components/Commons/Examination/ExaminationHeader.vue';
import { defineComponent } from 'vue';
import ExaminationRouter from '@/components/Commons/Examination/ExaminationRouter.vue';

export default defineComponent({
  name: 'GeneratePdf',
  components: {
    ExaminationRouter,
    ExaminationHeader
  }
});
